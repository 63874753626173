import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/handyhand/environment.dk';

import { Task } from '@models/task.model';
import { PriceBudgetSuggestionResponse } from '@components/guides/priceGuidePriceSuggester/interfaces/priceBudgetSuggestionResponse.type.interface';

export interface AssociatedTasksByTagAndLocation {
  region?: string;
  city?: string;
  keywords?: any; //string[];
  tagId?: number;
}

export interface PriceBudgetSuggestion {
  arithmeticAverage: number;
  weightedAverage: number;
  median: number;
  lowerQuartile: number;
  higherQuartile: number;
  tasks: Task[];
  variance: number;
  weightedVariance: number;
  intervalEstimation95Reliability: number;
  noOfFoundTasks: number;
}
@Injectable({
  providedIn: 'root',
})
export class TaskService {
  baseHref = `${environment.apiUrl}/tasks`;
  dashboardHref = `${environment.dashboardBaseRef}api/proxy`;

  constructor(private http: HttpClient) {}

  dynamicTipsImproveTaskDesc(combinedText: string): Observable<string[]> {
    const url = `${this.baseHref}/dynamic-tips-improve-task-desc`;
    return this.http.post<string[]>(url, { description: combinedText });
  }

  getPriceSuggestionsLimited(
    title: string,
    description: string,
    calculationLimit = 20,
    suggestedTaskLimit = 3,
  ): Observable<PriceBudgetSuggestionResponse> {
    const url = `${this.baseHref}/budget-suggestions?limit=${calculationLimit}&suggestedLimit=${suggestedTaskLimit}`;
    return this.http.post<any>(url, { title, description });
  }

  priceSuggester(data: any): Observable<any> {
    // PriceSuggesterParams
    const limit = data.limit || 40; // Number of tasks to consider in calculation
    const similarityThreshold = data.similarityThreshold || 0.7; // Cosine similarity
    const suggestedLimit = data.suggestedLimit || 40; // Number of tasks to return
    const url = `${this.baseHref}/predict-budget-suggester?limit=${limit}&similarityThreshold=${similarityThreshold}&suggestedLimit=${suggestedLimit}`;
    return this.http.post<any>(url, data);
  }

  fetchAssociatedTasksByTagAndLocation(
    data: AssociatedTasksByTagAndLocation,
  ): Observable<Task[]> {
    const url = `${this.baseHref}/fetch-associated-tasks-by-tag-location`;
    return this.http.post<Task[]>(url, data);
  }

  fetchAssociatedTasksByTag(
    tagId: number,
    wordLookup: undefined | string,
  ): Observable<Task[]> {
    const url = `${this.dashboardHref}/fetch-associated-tasks-by-tag/${tagId}`;
    let params = new HttpParams();
    if (wordLookup) {
      params = params.set('wordLookup', wordLookup);
    }
    return this.http.get<Task[]>(url, { params });
  }

  fetchSimiliarTasks(
    title: string,
    description: string,
    calculationLimit = 15,
    suggestedTaskLimit = 8,
  ): Observable<any> {
    const url = `${this.baseHref}/similiar-tasks?limit=${calculationLimit}&suggestedLimit=${suggestedTaskLimit}`;
    return this.http.post<any>(url, { title, description });
  }

  predictTimeEstimationPrompt(description: string): Observable<any> {
    const url = `${this.baseHref}/predict-time-estimation-prompt-from-desc`;
    return this.http.post<any>(url, { description });
  }

  getExampleTasks(mainTagId?: number): Observable<any> {
    const url = mainTagId
      ? `${this.baseHref}/example-completed-tasks?mainTagId=${mainTagId}`
      : `${this.baseHref}/example-completed-tasks`;
    return this.http.get<any>(url);
  }
}
